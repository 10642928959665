<template>
  <b-overlay :show="loading">
    <b-card header="Aggiungi Utente">
      <b-card-body>
        <validation-observer ref="rules">
          <b-form @submit.prevent="">

            <b-row>
              <b-col cols="12">

                <cs-validation
                  label="Nominativo"
                  rules="required"
                >
                  <template slot-scope="props">
                    <cs-input
                      id="nominativo"
                      v-model="model.nominativo"
                      label="Nominativo"
                      :state="props.errors.length === 0 ? null : false"
                    />
                  </template>

                </cs-validation>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <cs-validation
                  label="Email"
                  rules="required|email"
                >
                  <template slot-scope="props">
                    <cs-input
                      id="email"
                      v-model="model.email"
                      label="Email"
                      :state="props.errors.length === 0 ? null : false"
                    />
                  </template>

                </cs-validation>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <cs-validation
                  label="Ruolo"
                  rules="required"
                >
                  <template slot-scope="props">
                    <cs-select
                      id="ruolo"
                      v-model="model.ruoli"
                      multiple
                      label="Ruolo"
                      :state="props.errors.length === 0 ? null : false"
                      :options="ruoli"
                      :reduce="r => ({ id: r.id, ruoloId: r.ruoloId })"
                    />
                  </template>

                </cs-validation>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">

                <cs-select
                  id="gruppi"
                  v-model="model.gruppi"
                  label="Gruppi"
                  :options="gruppi"
                  multiple
                />

              </b-col>
            </b-row>

          </b-form>
        </validation-observer>

      </b-card-body>

      <b-card-footer>
        <cs-submit-button :click="add">
          Aggiungi
        </cs-submit-button>
      </b-card-footer>
    </b-card>
  </b-overlay>
</template>

<script>

export default {
  data() {
    return {
      loading: true,
      model: {
        nominativo: null,
        email: null,
        ruoli: [],
        gruppi: [],
      },
      ruoli: [],
      gruppi: [],
    }
  },
  created() {
    this.$remote.gruppi.all()
      .then(res => { this.gruppi = res.data.map(a => ({ id: a.id, ruoloId: a.ruoloId, label: a.nome })) })
      .catch(err => this.showReposonseMessage(err))
      .finally(() => { this.loading = false })

    this.$remote.values.ruoli_all()
      .then(res => { this.ruoli = res.data.map(a => ({ id: a.id, label: a.ruolo, ruoloId: a.ruoloId })) })
      .catch(err => this.showReposonseMessage(err))
      .finally(() => { this.loading = false })
  },
  methods: {
    add() {
      this.$refs.rules.validate()
        .then(success => {
          if (!success) return

          this.loading = true
          this.$remote.utenti.create(this.model)
            .then(() => this.showAddSuccessMessage())
            .catch(res => this.showReposonseMessage(res))
            .finally(() => { this.loading = false })
        })
    },
  },
}
</script>
